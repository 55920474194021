import React from "react";

const NoProjects = () => (
  <section className="no-projects">
    <h2>Add a Project!</h2>
    <p>You don't have any projects yet :-(</p>
  </section>
);

export default NoProjects;
